// MachineIndex.js
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MachineNav from './nav';
import { Route, Switch } from 'react-router-dom';
import AlertComponent from '../Util/AlertComponent';
import needAuth from '../../auth/needAuth';
import OrderTable from './table';
import OrderDetails from './details';
import EstimateNew from './estimations/new';
import EstimationsIndex from './estimations';
import OrderNav from './nav';
import OrderNew from './new';
import EstimationDetail from './estimations/details';
import ItemDetails from './items/details';

const OrderIndex = () => {
    return (

        <Container fluid>
            <Row>
                <AlertComponent />

                <div>
                    <Switch>
                        <Route exact path="/orders/:order_id/estimations/:id" component={needAuth(EstimationDetail)} />
                        <Route exact path="/orders/new" component={needAuth(OrderNew)} />
                        <Route exact path="/orders" component={needAuth(OrderTable)} />
                        <Route exact path="/orders/estimations" component={needAuth(EstimationsIndex)} />
                        <Route exact path="/orders/:id" component={needAuth(OrderDetails)} />
                        <Route exact path="/orders/:id/estimation/new" component={needAuth(EstimateNew)} />
                        <Route exact path="/orders/:order_id/items/:id" component={needAuth(ItemDetails)} />

                    </Switch>
                </div>



            </Row>
        </Container>
    );
};

export default OrderIndex;
