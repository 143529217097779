import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

function CreateAddressModal({ show, handleClose, handleCreateAddress }) {
  const [newAddress, setNewAddress] = useState({
    // Define the fields you need for a new address
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  });

  const [countryOptions,setCountryOptions] = useState([]);


  useEffect(() => {

    const getCountryOptions = async () => {
      const response = await axios.get("/countries");
      console.log(response.data)
      setCountryOptions(response.data);
    }
    getCountryOptions();
    setNewAddress({
      street: '123 Main Street',
      city: 'Cityville',
      state: 'ST',
      zipCode: '12345',
      country: 'United States',
      // Set other fields as needed
    });
  }, [show]); // Trigger the effect when the modal opens

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    let authToken = localStorage.getItem("token");
    try {
      // Add validation if needed
      const response = await axios.post('/addresses', newAddress, {
        headers: {
          Authorization: `${authToken}`,
        },
      });

      // Assuming the API response includes the newly created address
      const createdAddress = response.data.address;

      // Pass the created address to the parent component
      handleCreateAddress(createdAddress);
      handleClose();
    } catch (error) {
      console.error('Error creating address:', error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formStreet">
            <Form.Label>Street</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter street"
              name="street"
              value={newAddress.street}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter city"
              name="city"
              value={newAddress.city}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formState">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter state"
              name="state"
              value={newAddress.state}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formZipCode">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter zip code"
              name="zipCode"
              value={newAddress.zipCode}
              onChange={handleChange}
            />
          </Form.Group>


          <Form.Group controlId="formCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              as="select"
              name="country"
              value={newAddress.country}
              onChange={handleChange}
            >
              <option value="">Select Country</option>
              {countryOptions && countryOptions.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Address
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateAddressModal;
