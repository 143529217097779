
import './bootstrap.min.css';
import './App.css';

import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';


import AppNav from './components/AppNav';
import OrderIndex from './components/orders';
import Login from './components/Login';
import Register from './components/Register';
import needAuth from './auth/needAuth';
import HomeIndex from './components/home/index';
import Index from './components';

function App() {
  return (
    <Router>
      <div>
        <Row >
          <Col sm={12} md={10} lg={8} xl={7} style={{ margin: "0 auto" }}>
            <Card className='mt-5 has-shadow glass' style={{minHeight: "90vh"}}>
              <Card.Body>
                <Container fluid style={{ marginTop: '20px' }}>
                  <AppNav />
                  <div style={{ marginTop: '20px' }}>
                    <Switch>
                      <Route path="/" exact component={Index} />
                      <Route path="/home" exact component={HomeIndex} />
                      <Route path="/login" exact component={Login} />
                      <Route path="/register" exact component={Register} />
                      <Route path="/orders" component={needAuth(OrderIndex)} />
                    </Switch>
                  </div>
                </Container>
              </Card.Body>
            </Card>

          </Col>
        </Row>

      </div>
    </Router>
  );
}

export default App;
