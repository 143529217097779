// orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    customerName: '',
    partner_id: 0,
    customer_id: 0,
    status: 'pending',
    items: [],
    estimations: [],
    totalAmount: 0,
    estimationExists: false,
  },
  reducers: {
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
    setPartnerId: (state, action) => {
      state.partner_id = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customer_id = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(item => item._id !== action.payload);
    },
    // Add more reducers for other fields as needed
  },
});

export const {
  setCustomerName,
  setPartnerId,
  setCustomerId,
  setStatus,
  addItem,
  removeItem,
} = orderSlice.actions;

export default orderSlice.reducer;
