import React from 'react';
import { Card, Button, Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import timeAgo from '../../util/timeago';
import StatusBadge from '../Util/StatusBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCreditCard } from '@fortawesome/free-solid-svg-icons';
const OrderCard = ({ order }) => {

    return (
        <Card style={{ marginBottom: "1em" }} className='has-shadow glass'>

            <Card.Body>
                <Card.Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src={order.service && order.service.serviceIcon} style={{ width: '2em', height: '2em', background: 'white'}} className='me-3 p-1' />
                        <p>
                            <StatusBadge status={order.status} />
                        </p>


                    </div>
                    <Badge as={Button} variant="outline-dark" bg='dark' size="sm">
               
                        {order.service && order.service.name}</Badge>

                </Card.Title>
                <Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <strong>Created at:</strong> {timeAgo(order.orderDate)}

                            <br />
                            {order.estimation_id ? (
                                <>
                                    Estimation
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <strong> $ {order.totalPrice} </strong>
                                </>
                            ) : (
                                <>
                                    Estimations<Badge className='m-1' bg={order.estimations.length == 0 ? 'secondary' : 'info'}>{order.estimations.length}</Badge>

                                </>
                            )}

                            <br />

                        </div>

                        {order.estimation_id ? (
                            <Button size="md" as={Link} to={`orders/${order._id}`} variant="outline-success" className="m-3" >
                                <FontAwesomeIcon icon={faCreditCard} /> Pay
                            </Button>
                        ) : (
                            <Button size="md" as={Link} to={`orders/${order._id}`} variant="outline-primary" className="m-3" >
                                View
                            </Button>
                        )}

                    </div>
                </Card.Text>

            </Card.Body>

        </Card>
    );
};

export default OrderCard;