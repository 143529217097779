import React from 'react';
import { Spinner } from 'react-bootstrap'; // Import necessary Bootstrap components

const LoadingSpinner = ({ loading, children }) => {
  return (
    loading ? (
      <div className="text-center">
        <Spinner animation="border" role="status" className="mb-2 mt-4">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Loading...</p>
      </div>
    ) : (
      children
    )
  );
};

export default LoadingSpinner;
