export const parseFileName = (fileName) => {
    // Remove the file extension (e.g., ".stl")
    const baseName = fileName.replace(/\.[^/.]+$/, ' ');
  
    // Replace underscores and hyphens with spaces
    const replacedSpaces = baseName.replace(/[_-]/g, ' ');
  
    // Uppercase the first letter of each word
    const finalName = replacedSpaces.replace(/\b\w/g, (match) => match.toUpperCase());
  
    return finalName;
  };
  