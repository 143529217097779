import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Badge, Container, Col, Card, Row, Spinner } from 'react-bootstrap';
import { useHistory, useParams, NavLink } from 'react-router-dom/cjs/react-router-dom.min';

import CardTitle from '../Util/CardTitle';
import timeAgo from '../../util/timeago';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCancel, faShippingFast, faCreditCardAlt, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import ArchiveButton from '../Util/ArchiveButton';
import StatusBadge from '../Util/StatusBadge';
import { setAlert } from '../../slices/alertSlice';
import ItemCard from '../Util/ItemCard';
import LoadingSpinner from '../Util/LoadingSpinner';

const OrderDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [order, setOrder] = useState([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [address, setAddress] = useState({});
    const [service, setService] = useState({});
    const [apiLoading, setApiLoading] = useState(true);


    const handleCancel = async () => {
        setLoading(true);
        try {
            let authToken = localStorage.getItem('token');
            const response = await axios.get(`/orders/${id}/cancel`, { headers: { Authorization: `${authToken}` } });
            dispatch(setAlert({ message: response.data.message, variant: 'success' }));
            setLoading(false);
            history.push('/orders');
        } catch (error) {
            console.error('API Error:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    let authToken = localStorage.getItem('token');
                    const response = await axios.get(`/orders/${id}`, { headers: { Authorization: `${authToken}` } });
                    let orderData = response.data
                    setOrder(response.data);
                    setService(orderData.service);
                    const address = orderData.addres
                    if (address) {
                        setAddress(address);

                    }

                    setApiLoading(false);

                } catch (error) {
                    console.error('API Error:', error);
                }
            };

            fetchData();
        }
    }, [id]);

    const handleCheckout = async () => {
        setLoadingCheckout(true);
        try {
            const authToken = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id');
            let payload = {
                order_id: order._id,
                estimation_id: order.estimation_id,
                success_url: 'http://localhost:3000/orders/60f9b3b3e9b3a40015f3f2b2/estimations/60f9b3b3e9b3a40015f3f2b2',
                cancel_url: 'http://localhost:3000/orders/60f9b3b3e9b3a40015f3f2b2/estimations/60f9b3b3e9b3a40015f3f2b2',
            }
            let response = await axios.post(`/payments/checkout`, payload, {
                headers: { Authorization: authToken },
            });
            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            } else {
                dispatch(setAlert('Payment could not be completed', 'danger'));

            }

        } catch (error) {
            dispatch(setAlert('Payment could not be completed', 'danger'));

            console.error('API Error:', error);
        }
    };
    return (
        <Container fluid className="d-flex justify-content-center align-items-center" >
            <Col xs={6}>
                <CardTitle title="Order Details" />
                <LoadingSpinner loading={apiLoading}>
                    <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <StatusBadge status={order.status} />
                    </div>
          
                    <Row className='mt-4 mb-2'>
                        <Col>
                            <FontAwesomeIcon icon={faAddressCard} className='me-2' />
                            {`${address.street}, ${address.city}`}
                        </Col>
                        <Col>
                            <img src={service.serviceIcon} alt="service" width="30" height="30" className="d-inline-block align-text-top me-3" />
                            {service.name}
                        </Col>
                    </Row>
                    <h5 className='mt-3'>
                        Items          <Badge bg="secondary" className='ms-1'>
                            {order.items && order.items.length}
                        </Badge>
                    </h5>
                    <hr />
                    <div className="d-grid gap-2">
                        {order.items && order.items.map((item) => (
                            <ItemCard item={item} ViewItem={true} order_id={order._id} />

                        ))}
                    </div>
                    <h5 className='mt-3'>
                        Estimations     <Badge bg="secondary" className='ms-1'>
                            {order.estimations && order.estimations.length}
                        </Badge>
                    </h5>
                    <hr />
                    <div className="d-grid gap-2">
                        {order.estimations && order.estimations.map((estimation) => (

                            <>
                                {order.partner_id == estimation.partner_id ? (

                                    <Card key={estimation._id} border="success" className='glass'>

                                        <Card.Body>
                                            <Card.Title>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {estimation._id}
                                                    <strong className="ms-2">
                                                        ${estimation.totalPrice}
                                                    </strong>
                                                </div>
                                            </Card.Title>
                                            <Card.Subtitle>
                                                {timeAgo(estimation.created_at)}

                                            </Card.Subtitle>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>By: {estimation.partner_id}</span>
                                                <NavLink to={`/orders/${order._id}/estimations/${estimation._id}`} className="ms-2">

                                                </NavLink>

                                                <Button variant="outline-success" className="ms-2" onClick={handleCheckout}>
                                                    {loadingCheckout ? 'Loading...' : <>   <FontAwesomeIcon icon={faCreditCardAlt} /> Pay </>}

                                                </Button>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                ) : (
                                    <Card key={estimation._id} className='glass'>

                                        <Card.Body>
                                            <Card.Title>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {estimation._id}
                                                    <strong className="ms-2">
                                                        ${estimation.totalPrice}
                                                    </strong>
                                                </div>
                                            </Card.Title>
                                            <Card.Subtitle>
                                                {timeAgo(estimation.created_at)}

                                            </Card.Subtitle>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>Estimation Id: {estimation._id}</span>
                                                <NavLink to={`/orders/${order._id}/estimations/${estimation._id}`} className="ms-2">
                                                    <Badge variant="info" className="ms-2" bg="info">
                                                        View
                                                    </Badge>
                                                </NavLink>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                                }
                            </>

                        ))}
                    </div>
                    {order.status === 'pending'  || order.status === 'processing' || order.status === 'canceled' ? (
                        <>
                            <div className="d-grid gap-1">

                                {order.status === 'waiting_for_payment' ? (
                                    <Button variant='outline-danger' className='m-3 mb-1' size="lg" onClick={handleCancel}>
                                        <FontAwesomeIcon icon={faCancel} /> Cancel Order {loading ? 'Loading...' : ''}
                                    </Button>
                                ) : (
                                    <ArchiveButton type="order" payload={order} />

                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="d-grid gap-2">
                                <Button variant='outline-success' className='m-3 mb-1' as={NavLink}  to={`/orders/${order._id}/estimations/` + order.estimation_id} size="lg" >

                                    Go To Estimation
         
                                </Button>
                       
                            </div>
                        </>
                    )}
                </LoadingSpinner>
            </Col>
        </Container>

    );
};

export default OrderDetails;
