import { useState } from 'react';
import { Modal, Button, Form, InputGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { setAlert } from '../../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { addItem, removeItem } from '../../../slices/orderSlice';
import { useEffect } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { StlViewer } from 'react-stl-viewer';
import { generateImage } from '../../../util/exportAsImage';
import { parseFileName } from '../../../util/parseFileName';
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faTrash } from '@fortawesome/free-solid-svg-icons';
import { calculatePrintingCost } from '../../../util/calculateCost';
const ItemNew = ({ show, handleClose, service }) => {

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.stl',
    maxFiles: 1, // Limit to one file
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles[0]);
    },
  });
  const style = {
    top: 0,
    left: 0,
    width: '10em',
    margin: '0 auto',
    height: '10em',
    color: 'green',
  }
  const authToken = localStorage.getItem("token");

  const dispatch = useDispatch();
  const [materials, setMaterials] = useState([]);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [option, setOption] = useState([]);
  const [showRenderedSTL, setShowRenderedSTL] = useState(false);
  const [url, setUrl] = useState(null);
  const [optionValue, setOptionValue] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [makerShipEstimation, setMakerShipEstimation] = useState("");
  let [materialColor, setMaterialColor] = useState("green");
  const [showDropzone, setShowDropzone] = useState(true);
  const [itemFormData, setItemFormData] = useState({
    itemName: '',
    quantity: 1,
    material: '',
    color: '',
    fileUpload: '',
  });

  const handleItemFormChange = (e) => {
    const { name, value } = e.target;
    setItemFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleFileChange = async (file) => {
    setShowDropzone(false);
    const formData = new FormData();
    formData.append('stlFile', file);
    const estimation = "";
    renderSTL(file);
    setItemFormData((prevData) => ({ ...prevData, itemName: parseFileName(file.name) }));
    setMakerShipEstimation("Calculating...");

    const response = await axios.post('/utility/3d/estimation', formData, {
      headers: {
        Authorization: `${authToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.details["Estimated duration"]) {
      setMakerShipEstimation("MakerShip Estimation: $" + calculatePrintingCost(response.data.details["Estimated duration"], response.data.details["Filament used"]));
    }
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    let user_id = localStorage.getItem("user_id");
    const elementToCapture = document.getElementById('stl-container');

    const image = await generateImage(elementToCapture);

    itemFormData.customer_id = user_id;
    itemFormData.imageSrc = image;
    itemFormData.materialOptions = materialOptions;
    let response = await axios.post('/items', itemFormData, {
      headers: {
        Authorization: `${authToken}`,
      },
    });
    dispatch(addItem(response.data.item));
    dispatch(setAlert({ message: 'Item submitted successfully', variant: 'success' }));
    handleClose();
  };
  function getMaterials(method) {
    if (materials.length === 0 || !method) {
      return ["Select Service First"];
    }

    const foundMaterial = materials.find(material => material.method === method);

    return foundMaterial ? foundMaterial.materials : [];
  }

  function getMaterialOptions(method) {
    if (materials.length === 0 || !method) {
      return [];
    }

    const foundMaterial = materials.find(material => material.method === method);

    return foundMaterial ? foundMaterial.options : [];
  }

  const handleRemoveStl = () => {
    setShowDropzone(true);
    setShowRenderedSTL(false);
    setUrl(null);
  }
  const handleOptionChange = ({ target }) => {
    if (target.value === 'color') {
      setOptionValue('#563d7c');

    };
    setOption(target.value);
  }




  const handleOptionValueChange = ({ target }) => {
    if (option === 'color') {
      setMaterialColor(target.value);
    }
    setOptionValue(target.value);
  }
  const renderInputByOption = (option) => {
    if (option === 'color') {
      return (
        <InputGroup className="mt-3">
          <Form.Control
            onChange={handleOptionValueChange}
            type="color"
            value={optionValue}
            title="Choose your color"
          />
          <Button
            onClick={handleAddMaterialOption}
            variant="outline-primary"
            title="Action"
            id="segmented-button-dropdown-2"
            alignRight
          >
            Add

          </Button>
        </InputGroup>
      )
    } else if (option === 'infill') {
      return <input type="number" name="infill" value={itemFormData.infill} onChange={handleItemFormChange} />;
    } else if (option === 'wall thickness') {
      return <input type="number" name="wallThickness" value={itemFormData.wallThickness} onChange={handleItemFormChange} />;
    }

  }
  const handleAddMaterialOption = () => {
    setMaterialOptions((prevOptions) => [
      ...prevOptions,
      { option: option, value: optionValue },
    ]);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {


        const authToken = localStorage.getItem('token');
        const response = await axios.get(`/materials`, { headers: { Authorization: authToken } });
        setMaterials(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [itemFormData]);

  const renderSTL = async (file) => {

    if (!file) {
      alert('Please upload an STL file first.');
      return;
    }
    let url = URL.createObjectURL(file)
    setUrl(url);
    setShowRenderedSTL(true)
  }

  const renderTooltip = (props) => (
    <Tooltip background="danger"  id="button-trash-remove" {...props} onClick={handleRemoveStl}>
      <FontAwesomeIcon icon={faTrash} />
    </Tooltip>
  );
  return (


    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {itemFormData.itemName && (
            <Form.Group controlId="itemName">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter item name"
                name="itemName"
                value={itemFormData.itemName}
                onChange={handleItemFormChange}
                required
              />
            </Form.Group>
          )}
          <Form.Group controlId="quantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter quantity"
              name="quantity"
              value={itemFormData.quantity}
              onChange={handleItemFormChange}
              required
            />
          </Form.Group>


          <Form.Label>Material</Form.Label>
          <Form.Select
            name="material"
            value={itemFormData.material}
            onChange={handleItemFormChange}
          >
            {getMaterials(service.type).map((material) => (
              <option value={material}>{material}</option>
            ))}
          </Form.Select>
          <div>
            <Form.Select
              name="materialOption"
              onChange={handleOptionChange}
              className='mt-3'
            >
              <option value="">Select Material Option</option>
              {getMaterialOptions(service.type).map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>

            {renderInputByOption(option)}


            <div>
              <ul>
                {materialOptions.map((material, index) => (
                  <li key={index}>
                    Option: {material.option}, Value: {material.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Form.Group>
            {showDropzone && (
          <div {...getRootProps()} style={dropZoneStyle}>
          <input {...getInputProps()} />
          <p> <FontAwesomeIcon icon={faCube} /> Upload STL File</p>
        </div>
            )}
  
          </Form.Group>
   
              {showRenderedSTL ? (
                <>
                       <OverlayTrigger
                       placement="right"
                       delay={{ show: 250, hide: 2000 }}
                       overlay={renderTooltip}>
                       <div id="stl-container" style={style}>
                <StlViewer

                  modelProps={{ color: materialColor }}
                  style={style} // Make sure 'style' is defined or replace it with the appropriate styles
                  orbitControls // If these are boolean props, no need to set them to true explicitly
                  shadows // If these are boolean props, no need to set them to true explicitly
                  url={url} // Pass the URL to the StlViewer component
                />
                         </div>
                         
          </OverlayTrigger>
                 <div>

                 <p style={{ textAlign: 'center' }}>{makerShipEstimation} *</p>
               </div>
               </>
              ) : (
                <></>
              )}
   

   
          <div className="d-grid gap-2">
            <Button variant="outline-primary" className='m-3' type="submit" >
              Save Item
            </Button>

          </div>
          <sub>*The estimation price can vary based on materials and machines used this is a rough estimation</sub>

        </Form>
      </Modal.Body>
    </Modal>
  );
};
const dropZoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default ItemNew;
