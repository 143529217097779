import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../modals/ConfirmationModal';
import axios from 'axios';
const ArchiveButton = ({type, payload}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleArchive = () => {
    setShowConfirmation(true);
  };

  const handleConfirmArchive = async () => {
    let response;
    const authToken = localStorage.getItem('token');
    setLoading(true);
    if(type == 'order'){
        response = await axios.get(`/orders/${payload._id}/archive`, {
            headers: { Authorization: authToken },
        });
    }
    setShowConfirmation(false);
    setLoading(false);
  };

  const handleCancelArchive = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <Button variant="outline-danger" onClick={handleArchive} className='m-3' type="submit" size="lg">
        {loading ? 'Loading...' : (
          <>
            <FontAwesomeIcon icon={faArchive} /> Archive
          </>
        )}
      </Button>

      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCancelArchive}
        onConfirm={handleConfirmArchive}
        actionText="archive this item"
        loading={loading}
      />
    </>
  );
};

export default ArchiveButton;
