import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const checklistItems = [
    {
        text: 'Verify your email',
        description: 'We sent you an email with a link to verify your email address. Please click the link to verify your email address.',
        completed: true,
        completedText: 'Email verified',

    },
    {
        name: "machines",
        text: 'Add your first machine',
        description: 'Add your first machine to start receiving orders',
        completed: false,
        completedText: 'Machine added',
        link: '/machines/new'
    },
    {
        name: "payment",
        text: 'Add your payment method',
        description: 'Add your payment method to start receiving payments',
        completed: false,
        completedText: 'Payment method added',
        link: '/payments/register'
    },
    {
        name: "estimation",
        text: 'Make your first estimate',
        description: 'Make your first estimate to commplete your first order',
        completed: false,
        completedText: 'Estimate made',
        link: '/orders'
    },
    {
        name: "order",
        text: 'Complete your first order',
        description: 'Complete your first order to start making money!',
        completed: false,
        completedText: 'Order completed'
    },

];
const OnboardingChecklist = ({progress}) => {

    if(progress){
        checklistItems.forEach(item => {
            if(progress[item.name]){
                item.completed = true;
            }
        })
    }
    return (

        <Card className='glass ' >
            <Card.Body>
                <Card.Title> Get Started</Card.Title>
                <ListGroup as="ol" numbered>
                    {checklistItems.map((item, index) => {
                        return (
                            item.completed ? <>
                                <ListGroup.Item as="li"><FontAwesomeIcon icon={faCheck} className='text-success me-2' />{item.completedText}</ListGroup.Item>

                            </> : <>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{item.text}</div>
                                        {item.description}
                                    </div>
                                    {checklistItems[index - 1] && checklistItems[index - 1].completed ?
                                        <Button variant="primary" as={NavLink} to={item.link}>
                                            Complete
                                        </Button> : ""}
                                </ListGroup.Item>
                            </>
                        )
                    })}
                </ListGroup>
            </Card.Body>
        </Card>


    )

}

export default OnboardingChecklist;   