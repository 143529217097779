// MachineTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../../slices/alertSlice';
import timeAgo from '../../../util/timeago';
const EstimationsIndex = () => {
    const [estimations, setEstimations] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch(); 
    let authToken = localStorage.getItem('token');
    let user_id = localStorage.getItem('user_id');
    useEffect(() => {

        axios.get('/partner/' + user_id + '/estimations', { headers: { Authorization: `${authToken}` } })
            .then(response => {
                if (Array.isArray(response.data.estimations)) {
                    setEstimations(response.data.estimations);
                    
                } else {
                    console.error('API Error: Response data is not an array');
                    setEstimations([]);
                }
            })
            .catch(error => {
                console.error('API Error:', error);
                setEstimations([]);
            });
    }, []);

    const handleArchive = (row) => {
        // Handle archive button click
        console.log('Archive:', row);
    };


    return (
        <Table hover>
            <thead>
                <tr>
                    <th>Customer Name</th>
                    <th>Status</th>
                    <th>Amount</th>
                    <th>Created at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {estimations.map((estimation) => (
                    <tr key={estimation._id}>
                        <td>{estimation.customerName}</td>
                        <td></td>
                        <td>{estimation.totalAmount}</td>
                        <td>{timeAgo(estimation.orderDate)}</td>

                        <td>
                            <Button  as={Link} to={`machines/${estimation._id}/edit`} variant="outline-info" className='me-3'>
                                Edit
                            </Button>
                            <Button variant="danger" onClick={() => handleArchive(estimation)}>
                                Reject
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default EstimationsIndex;
