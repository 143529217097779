import { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMapMarkerAlt, faTruck, faCheckCircle, faCheckToSlot, faUserCheck, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
function ServiceDropdown({ services, service, setService }) {

  const handleSelectService = (service) => {
    console.log(service)
    setService(service);
  }
  return (
    <>
      <DropdownButton
        style={{ width: '100%' }}

        as={ButtonGroup}
        key={services._id}
        variant={service.name ? 'outline-success' : 'outline-secondary'}
        title={service.name ? (
          <>
            <FontAwesomeIcon className='me-1' icon={faCircleCheck} />

            {service.name}
          </>
        ) : (
          "Select a service"
        )}
      >
        {services.map((service) => (

          <Dropdown.Item eventKey="1" onClick={() => handleSelectService(service)}>

            <img src={service.serviceIcon} alt="service" width="30" height="30" className="d-inline-block align-text-top me-3" />
            {service.name}</Dropdown.Item>

        ))}

      </DropdownButton>

    </>
  );
}

export default ServiceDropdown;