// MachineTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/alertSlice';
import { font } from '../../slices/alertSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import timeAgo from '../../util/timeago';
import OrdersCardList from './CardList';


const OrderTable = () => {
    const [orders, setOrders] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [apiLoading, setApiLoading] = useState(true);
    const dispatch = useDispatch();
    let authToken = localStorage.getItem('token');
    let user_id = localStorage.getItem('user_id');
    useEffect(() => {
        axios.get(`/customer/${user_id}/orders`, { headers: { Authorization: `${authToken}` } })
          .then(async (response) => {
            if (Array.isArray(response.data.orders)) {
              const ordersArr = await Promise.all(
                response.data.orders.map(async (order) => {
                  if (order.service_id) {
                    try {
                      const serviceResponse = await axios.get(`/services/${order.service_id}`, { headers: { Authorization: `${authToken}` } });
                      order.service = serviceResponse.data.service;
                    } catch (serviceError) {
                      console.error('Error fetching service:', serviceError);
                    }
                  }
                  return order;
                })
              );
      
              setOrders(ordersArr);
              setApiLoading(false);
            } else {
              console.error('API Error: Response data is not an array');
              setOrders([]);
            }
          })
          .catch((error) => {
            console.error('API Error:', error);
            setOrders([]);
          });
      }, [user_id, authToken]); // Add dependencies if needed
      

    const handleArchive = (row) => {
        // Handle archive button click
        console.log('Archive:', row);
    };


    return (
        <OrdersCardList orders={orders} loading={apiLoading} />
    );
};

export default OrderTable;
