import { configureStore } from '@reduxjs/toolkit'
import alertReducer from './slices/alertSlice';
import authReducer from './slices/authSlice';
import orderReducer from './slices/orderSlice';

export default configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    order: orderReducer,
  },
})