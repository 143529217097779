import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenticated, selectAuthenticated } from '../slices/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHome, faListUl, faSignOut } from '@fortawesome/free-solid-svg-icons';
import makershiplogo from './makership-logo-1000-t.png'

const AppNavbar = () => {
    // Check if the token is set in localStorage
    const isAuthenticated = useSelector(selectAuthenticated);
    const dispatch = useDispatch();
    const history = useHistory(); // Get the history object
    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(setAuthenticated(false));
        history.push('/');

    };

    return (
        <Navbar collapseOnSelect expand="lg" className='' variant="light">
            {isAuthenticated ? (
                <Container fluid>
                    <Navbar.Brand href="/">                <img src={makershiplogo} width="50" height="50" className="d-inline-block align-top ms-2" alt="Maker ship logo" />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link
                                style={{ fontSize: "1.5em" }}
                                as={NavLink}
                                exact
                                to="/home"
                                className={({ isActive, isPending }) =>
                                    isPending ? 'pending' : isActive ? 'active' : ''
                                }
                            >
                                <FontAwesomeIcon icon={faHome} className='me-2' />
                                Home
                            </Nav.Link>

                            <Nav.Link
                                as={NavLink}
                                style={{ fontSize: "1.5em" }}

                                to="/orders"
                                className={({ isActive, isPending }) =>
                                    isPending ? 'pending' : isActive ? 'active' : ''
                                }
                            >
                                <FontAwesomeIcon icon={faListUl} className='me-2' />

                                Orders
                            </Nav.Link>

                        </Nav>
                        <Nav>
                            <Nav.Link
                                style={{ fontSize: "1.5em" }}
                            >
                                <FontAwesomeIcon icon={faBell} />
                            </Nav.Link>
                            <Nav.Link
                                style={{ fontSize: "1.5em" }}
                            >
                                <Button variant="outline-info" onClick={handleLogout} className="p-2">
                                    <FontAwesomeIcon icon={faSignOut} className="me-1" />
                                    Logout
                                </Button>
                            </Nav.Link>

                        </Nav>
                    </Navbar.Collapse>

                </Container>

            ) : (
                <Container>
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav>

                        </Nav>
                        <Nav>
                            <Nav.Link
                                as={NavLink}
                                exact
                                to="/login"
                                className={({ isActive, isPending }) =>
                                    isPending ? 'pending' : isActive ? 'active' : ''
                                }
                            >
                                Login
                            </Nav.Link>
                            <Nav.Link
                                as={NavLink}
                                exact
                                to="/register"
                                className={({ isActive, isPending }) =>
                                    isPending ? 'pending' : isActive ? 'active' : ''
                                }
                            >
                                Register
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            )}



        </Navbar>
    );
};

export default AppNavbar;
