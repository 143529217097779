import React, { useState } from 'react';
import { Button, Form, Container, Badge, Col, Card, Row } from 'react-bootstrap';
import ItemNew from './items/new';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from '../../slices/orderSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import axios from 'axios';
import { setAlert } from '../../slices/alertSlice';
import CardTitle from '../Util/CardTitle';
import { useEffect } from 'react';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import ItemCard from '../Util/ItemCard';
import ServiceDropdown from '../Util/ServiceDropdown';
import AddressDropdown from '../Util/AddressDropdown';
import { useHistory } from 'react-router-dom';
const OrderNew = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showItemModal, setShowItemModal] = useState(false);
    const order = useSelector((state) => state.order);
    const [services, setServices] = useState([]);
    const [service, setService] = useState({ type: "" });
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState({ type: "" });
    const [orderFormData, setOrderFormData] = useState({
        customerName: '',
        partner_id: 0,
        customer_id: 0,
        status: 'pending',
        items: [],
        totalAmount: 0,
    });

    const handleServiceChange = (e) => {
        const { name, value } = e.target;
        setOrderFormData((prevData) => ({ ...prevData, [name]: value }));
        setService(services.find((service) => service._id === value));
    }
    const handleOrderFormChange = (e) => {
        const { name, value } = e.target;
        setOrderFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleItemModalClose = () => setShowItemModal(false);
    const handleItemModalShow = () => setShowItemModal(true);

    const handleItemLink = (itemId) => {
        // Add the selected item to the order's items array
        setOrderFormData((prevData) => ({ ...prevData, items: [...prevData.items, itemId] }));
        handleItemModalClose();
    };


    const handleItemRemove = (itemId) => {
        dispatch(removeItem(itemId));
    };
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let authToken = localStorage.getItem("token");
            let user_id = localStorage.getItem("user_id");
            orderFormData.customer_id = user_id;
            orderFormData.address_id = address.id;
            orderFormData.service_id = service._id;
            orderFormData.country = address.country;
            // Check if no items are added to the order
            if (order.items.length === 0) {
                throw new Error("No items added to the order. Please add items before submitting.");
            }

            // Set order items
            orderFormData.items = order.items;

            let response = await axios.post('/orders', orderFormData, {
                headers: {
                    Authorization: `${authToken}`,
                },
            });
            history.push("/orders");
            dispatch(setAlert({ message: response.data.message || 'Order submitted successfully', variant: 'success' }));

        } catch (error) {
            let errorMessage;
            if (error.message) {
                errorMessage = error.message || 'Order submission failed. Please fill in all required fields.';
            } else if (error.response) {
                errorMessage = error.response.data.message || 'Order submission failed. Please try again.';
            } else if (error.request) {
                errorMessage = 'No response from the server. Please check your internet connection or try again later.';
            } else {
                errorMessage = 'An unexpected error occurred. Please try again later.';
            }

            dispatch(setAlert({ message: errorMessage, variant: 'danger' }));
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/services`, { headers: { Authorization: localStorage.getItem('token') } });
                setServices(response.data.services);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchAddressData = async () => {
            let user_id = localStorage.getItem("user_id");

            try {
                const response = await axios.get(`/customer/` + user_id + '/addresses', { headers: { Authorization: localStorage.getItem('token') } });
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    
        fetchAddressData();
        fetchData();
    }, []);

    return (
        <Container fluid className="d-flex justify-content-center align-items-center">
            <Col xs={6}>
                <CardTitle title="Create Order" />
                <Form onSubmit={handleSubmit}>

                   
                    <Row>
                        <Col>
                            <Form.Group >

                                <ServiceDropdown services={services} service={service} setService={setService} />


                            </Form.Group>
                        </Col>
                        <Col>   <Form.Group >

                            <AddressDropdown addresses={addresses} address={address} setAddress={setAddress} />
                        </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Form.Group controlId="items" className='mt-3'>
                        <Form.Label>Items</Form.Label>
                        <Button variant="outline-primary" size='sm' className="m-2" onClick={handleItemModalShow}>
                            <FontAwesomeIcon icon={faAdd} /> Add Item
                        </Button>
                        <div className="d-grid gap-2">
                            {order.items.map((item) => (
                                <ItemCard item={item} handleItemRemove={handleItemRemove} />

                            ))}
                        </div>
                    </Form.Group>


                    <div className="d-grid gap-2">
                        <Button variant="outline-success" className='m-3' type="submit" size="lg">
                            Create Order
                        </Button>
                    </div>
                </Form>
            </Col>


            {/* Item Modal */}


            <ItemNew show={showItemModal} handleClose={handleItemModalClose} service={service} />


        </Container>
    );
};

export default OrderNew;
