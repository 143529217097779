import React, { useState } from 'react';
import { Form, Col, Button, Row } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setAlert } from '../../../slices/alertSlice';
import { useDispatch } from 'react-redux';

const EstimateNew = () => {
    const { id } = useParams(); 
    let user_id = localStorage.getItem('user_id');
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({

        description: '',
        price: 0,
        shippingCost: 0,
        timeEstimation: 0,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            formData.partner_id = user_id;
            formData.order_id = id;
            let authToken = localStorage.getItem("token");
            let response = await axios.post('/estimations', formData, {
                headers: {
                    Authorization: `${authToken}`,
                },
            });
            dispatch(setAlert({ message: 'Estimation submitted successfully', variant: 'success' }));
            setFormData({
                description: '',
                price: 0,
                shippingCost: 0,
                timeEstimation: 0,
            });
        } catch (error) {
            // Handle errors (e.g., show an error message)
            dispatch(setAlert({ message: error.message, variant: 'danger' }));
            console.error('Error submitting estimation:', error);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>

                <Col md={6}>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>


                <Col md={6}>
                    <Form.Group controlId="price">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Price"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="shippingCost">
                        <Form.Label>Shipping Cost</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter Shipping Cost"
                            name="shippingCost"
                            value={formData.shippingCost}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>


                <Col md={6}>
                    <Form.Group controlId="timeEstimation">
                        <Form.Label>Time Estimation</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Time Estimation"
                            name="timeEstimation"
                            value={formData.timeEstimation}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>



            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
};

export default EstimateNew;
