import html2canvas from 'html2canvas';
import axios from 'axios';

export const generateImage = async (elementToCapture) => {
  try {
    // Capture the content of the HTML element
    const canvas = await html2canvas(elementToCapture);

    // Create an image element
    const image = new Image();
    image.src = canvas.toDataURL();


    const formData = new FormData();
    formData.append('file', dataURItoBlob(image.src), 'stl_generated_image.png');

    const response = await axios.post('/files/upload', formData, {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data',
      },
    });


    return response.data.url
  } catch (error) {
    console.error('Error generating or uploading image:', error);
    return null;
  }
};

// Helper function to convert data URI to Blob
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}
