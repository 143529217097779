import React from 'react';
import { Row, Col, Container, Card, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faIndustry, faSearch, faBox, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

const Index = () => {
    const imageUrls = [
        'https://lh5.googleusercontent.com/nNhOtD_CxE4I4X6i4Plbgd6dFGzfRsbSQU-g_EVdjEvfeKZDzGBzh_oj7kL2fiN10jbKvRI_ZtwV652hSZkyn4y_WJI3sLemGEPYkHp3W987DsoFMkQPzRJ03Qp7tLozZ4X22XOK',
        'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fthumbs.dreamstime.com%2Fb%2Fclose-up-cnc-machine-work-106231560.jpg&f=1&nofb=1&ipt=2cfda16ee341b73a13ee7c4adca27a34494eb64a9dee8170bb295517c837c0d2&ipo=images',
        'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fdm0qx8t0i9gc9.cloudfront.net%2Fthumbnails%2Fvideo%2Fao-L6gr%2Fvideoblocks-laser-cutting-machine-at-work-acrylic-plastic-cutting-close-up_snxwnsz3qg_thumbnail-1080_01.png&f=1&nofb=1&ipt=bd0aa9ef53e57609d7349be618232475bb5e3716843d875403646fb85778cd1d&ipo=images',
    ];

    const testimonials = [
        {
            author: 'John Doe',
            quote: 'The Makership Partner platform has revolutionized the way I collaborate with other makers. It\'s a fantastic community!',
        },
        {
            author: 'Jane Smith',
            quote: 'Joining Makership Partner was the best decision for my maker journey. The community is supportive, and the projects are inspiring.',
        },
        // Add more testimonials as needed
    ];

    return (
        <Container fluid className="d-flex justify-content-center align-items-center">
            <Row>
                <div className='mt-5 text-center'>
                    <Card className='' style={{ border: "none", background: 'transparent' }}>
                        <Card.Body style={{ width: "80%", margin: "0 auto" }}>
                            <Card.Title className='mt-5'><h1>Welcome To Makership</h1></Card.Title>
                            <Card.Text>
                            <p>Welcome to our collaborative making platform! Are you brimming with creative ideas but need the right hands to bring them to life? Look no further!</p>

<ul style={{textAlign: "left"}}>
    <li className='m-4'>🚀 **Sign Up and Place Orders:**
        Join our platform and unlock a world of possibilities. Sign up, share your project ideas, and place orders for skilled makers to bring them to fruition.</li>

        <li className='m-4'>🛠️ **Connect with Makers:**
        Tap into a community of talented makers ready to turn your vision into reality. Connect with experienced creators who have the skills and passion to fulfill your orders.</li>

</ul>            </Card.Text>
                            <div className='d-flex justify-content-center'>
                                <Link to="/login">
                                    <Button variant="primary" className="mr-2 me-2">
                                        Sign In
                                    </Button>
                                </Link>

                                <Link to="/register">
                                    <Button variant="secondary">
                                        Register
                                    </Button>
                                </Link>
                            </div>

                            <Row className="mt-5">
                                {imageUrls.map((imageUrl, index) => (
                                    <Col key={index} xs={12} md={4}>
                                        <Image src={imageUrl} alt={`Image ${index + 1}`} fluid style={{ borderRadius: "10px", height: "100%" }} />
                                    </Col>
                                ))}
                            </Row>

                            <div className="mt-5">
                                <h2>Sign Up Now!</h2>
                                <p>
                                    Do you have a 3D printer, laser cutter, or CNC machine that you want to make money with? Or are you interested in starting your own micro-factory? Sign up now and become a part of the Makership Partners community!
                                </p>
                                <a  href="https://partners.makership.io">
                                    <Button variant="primary">
                                        Sign Up as a Maker
                                    </Button>
                                </a>
                            </div>
                  


                            <div className="mt-5">
                                <h2>Testimonials</h2>
                                {testimonials.map((testimonial, index) => (
                                    <Card key={index} className="mt-3">
                                        <Card.Body>
                                            <Card.Text>"{testimonial.quote}"</Card.Text>
                                            <Card.Subtitle className="mb-2 text-muted">- {testimonial.author}</Card.Subtitle>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>

                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </Container>
    );
};

export default Index;
