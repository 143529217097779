import React from 'react';
import OrderCard from './Card';
import CardTitle from '../Util/CardTitle';
import { Container, Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../Util/LoadingSpinner';
const OrdersCardList = ({ orders, loading }) => {
    return (

        <Container fluid className="d-flex justify-content-center align-items-center" >
            <Row style={{minWidth: "80%"}}>

                <CardTitle title="Orders" />
                <LoadingSpinner loading={loading}>
                    {orders.map((order) => (
                        <Col xs={12} xl={6}>
                            <OrderCard key={order._id} order={order} />
                        </Col>
                    ))}
                </LoadingSpinner>


            </Row>


        </Container>
    );
};
export default OrdersCardList;