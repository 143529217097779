import React from 'react';
import { Card, Button, Badge } from 'react-bootstrap'; // Import necessary Bootstrap components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const ItemCard = ({ item, handleItemRemove, ViewItem,price,order_id }) => {
    return (
        <Card key={item._id}>
            <Card.Body>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <img src={item.imageSrc} alt="item" width="70" height="70" />
                        <span>Item Name: {item.itemName}</span>
                    </div>
                    {handleItemRemove && (
                        <Button
                            as={Button}
                            style={{ height: '50%' }}
                            variant="danger"
                            className="ms-2"
                            bg="danger"
                            onClick={() => handleItemRemove(item._id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    )}

                    {ViewItem && (
                        <NavLink to={`/orders/${order_id}/items/${item._id}`} className="ms-2">
                            <Badge variant="info" className="ms-2" bg="info" as={Button}>
                                <FontAwesomeIcon icon={faEye} className="me-1" />
                                View
                            </Badge>
                        </NavLink>

                    )}
                    {price && (
                        <span className='me-3'><strong>Price:</strong> ${item.price}</span>
                    )}  
                
                </div>
            </Card.Body>
        </Card>
    );
};

export default ItemCard;
