import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Badge, Container, Col, Card } from 'react-bootstrap';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';

import { setAlert } from '../../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import CardTitle from '../../Util/CardTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import ItemCard from '../../Util/ItemCard';
import LoadingSpinner from '../../Util/LoadingSpinner';
const ItemDetails = () => {
    const { order_id, id } = useParams();
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        const authToken = localStorage.getItem('token');

        const fetchItemData = async () => {
            try {
                const response = await axios.get(`/items/${id}`, {
                    headers: { Authorization: authToken },
                });

                setItem(response.data.item);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching estimation data:', error);
            }
        };


        if (id) {
            fetchItemData();
        }


    }, [id, order_id]);


    return (
        <Container fluid className="d-flex justify-content-center align-items-center" >
            <Col xs={6}>
                <CardTitle title="Item Details" />
                <LoadingSpinner loading={loading}>
                    <Card style={{ width: '25rem', margin: "0 auto" }}>
                        <Card.Img variant="top" src={item.imageSrc} alt={item.itemName} />
                        <Card.Body>
                            <Card.Title>{item.itemName}</Card.Title>
                            <Card.Text>
                                <strong>Item ID:</strong> {item._id}
                            </Card.Text>
                            <Card.Text>
                                <strong>Quantity:</strong> {item.quantity}
                            </Card.Text>
                            <Card.Text>
                                <strong>Material:</strong> {item.material}
                            </Card.Text>
                            <Card.Text>
                                <strong>Color:</strong> {item.color}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </LoadingSpinner>

            </Col>


        </Container>
    );
};

export default ItemDetails;
