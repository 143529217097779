// alertSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  type: '', // success, error, warning, info, etc.
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.message = action.payload.message;
      state.variant = action.payload.variant;
    },
    clearAlert: (state) => {
      state.message = '';
      state.variant = '';
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;

export default alertSlice.reducer;
