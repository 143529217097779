import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMapMarkerAlt, faTruck, faPlaneCircleCheck, faHouseCircleCheck } from '@fortawesome/free-solid-svg-icons';
import CreateAddressModal from '../modals/CreateAddressModal';

function AddressDropdown({ addresses, address, setAddress }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCreateAddress = (newAddress) => {
    // Add logic to handle the creation of a new address
    // You may want to update the state or perform an API call here
    console.log('Creating new address:', newAddress);
  };

  const handleSetAddress = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  return (
    <>
      <DropdownButton
        style={{ width: '100%' }}
        as={ButtonGroup}
        key={address._id}
        variant={address.street ? 'outline-success' : 'outline-secondary'}
        title={address.street ? (
          <>
   <FontAwesomeIcon className='me-1' icon={faHouseCircleCheck} /> 
            {`${address.street} , ${address.city}`}
                      </>
        ) : (
          <>
            <FontAwesomeIcon className='me-3' icon={faMapMarkerAlt} />
            Select an address
          </>
        )}
      >
        {addresses.map((a) => (
          <Dropdown.Item key={a._id} onClick={() => handleSetAddress(a)}>
            {`${a.street} , ${a.city}`}
          </Dropdown.Item>
        ))}
        <Dropdown.Item onClick={handleShowModal}>
          <FontAwesomeIcon icon={faPlusCircle} className='me-2' />
          Add new address
        </Dropdown.Item>
      </DropdownButton>

      <CreateAddressModal
        show={showModal}
        handleClose={handleCloseModal}
        handleCreateAddress={handleCreateAddress}
      />
    </>
  );
}

export default AddressDropdown;
