function parsePrintTime(printTime) {
    const [hours, minutes] = printTime.split(':').map(Number);
    return hours + minutes / 60;
  }

  function calculateMaterialUsed(length) {
    const lengthInMillimeters = parseFloat(length.replace("mm", ""));
    const filamentDiameter = 1.75; // in millimeters
    const materialDensity = 1.24; // replace with the actual density of your material
    const radius = filamentDiameter / 2;
    const volume = Math.PI * Math.pow(radius, 2) * lengthInMillimeters;
    const volumeCentimeters = volume / 1000; // Convert to cubic centimeters
    const weightGrams = volumeCentimeters * materialDensity;
    const weightKilograms = weightGrams / 1000; // Convert to kilograms
    return weightKilograms;
  }
  
  // Example usage:

  
  
  
  export function calculatePrintingCost(printTimeHours, lengthOfFilament) {
    const materialUsed = calculateMaterialUsed(lengthOfFilament);

    printTimeHours = parsePrintTime(printTimeHours);

    // Define your cost factors
    const materialCostPerKg = 20; // Replace with your actual material cost per kilogram
    const laborCostPerHour = 15; // Replace with your actual labor cost per hour
    const energyCostPerHour = 0.10; // Replace with your actual energy cost per hour
    const overheadMaintenanceCostPerHour = 1; // Replace with your actual overhead and maintenance cost per hour
    const printerDepreciationCostPerHour = 0.5; // Replace with your actual printer depreciation cost per hour
    const wasteFailedPrintsCost = 2.5; // Replace with your actual cost for waste and failed prints
  
    // Calculate individual costs
    const materialCost = materialUsed * materialCostPerKg;
    const laborCost =  10;
    const energyCost = printTimeHours * energyCostPerHour;
    const overheadMaintenanceCost = printTimeHours * overheadMaintenanceCostPerHour;
    const printerDepreciationCost = printTimeHours * printerDepreciationCostPerHour;
  
    // Assuming support material is 10% of the main material used
    const supportMaterialCost = 0.1 * materialCost;
  
    // Calculate total cost
    const totalCost = materialCost + laborCost + energyCost + overheadMaintenanceCost + printerDepreciationCost + supportMaterialCost + wasteFailedPrintsCost;
  
    return totalCost.toFixed(2);
  }
  

  